import { Box, Button, Container, FormControl, FormLabel, HStack, Heading, Input, Link, Stack, Text, Textarea, VStack, useToast } from "@chakra-ui/react"
import { IconMail, IconPhone } from '@tabler/icons';
import React, { useEffect, useState } from "react";
import { ValidationError, useForm } from '@formspree/react';

import {MdMail} from 'react-icons/md'
import validator from 'validator'

export default function ContactForm() {

  var toast = useToast()

  const [state, handleSubmit] = useForm("xjvdglyz");

  if (state.succeeded) {
    toast({
      status: 'success',
      title: "Message was sent!"
     })
  }


  //initial values + setting new ones by typing in input
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    company: "",
    message: ""
  })
  //error fields
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

   const handleChange = (input:any) => (e:any)  => {
     const value = e.currentTarget.value;
     
     setFormValues(prevState => ({
       ...prevState,
      [input]: value
  }));
     
   };

  //form validation
  const handleFormData = (event: React.FormEvent<HTMLFormElement>) => {

    // const [state, handleSubmit] = useForm("mnqrjvnq");
    event.preventDefault();

    setFormErrors(validate(formValues));
    setIsSubmit(true) 

  }

  useEffect(() => {
    console.log(formErrors);
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      // useForm("mnqrjvnq")
    }
  }, [formErrors]);

  const validate = (values: any) => {
    
    type errorType = {
      fullName?: string,
      message?: string
    }
    const errors: errorType ={}

    if (! validator.isLength(values.fullName, {min: 2, max: 100})) {
      errors.fullName = "Full name is too short"
    }

    if (!validator.isLength(values.message, { min: 10, max: 1000 })) {
      errors.message = "Message is too short"
    }

    return errors
  }

    return (
        <Box as="section" bg="gray.50" maxW="100%" margin="auto" px="50px" pt="32" pb="244px" >
            <HStack position="relative" minHeight="inherit" flexDirection={{ base: "column", md: "row" }} justifyContent={{ base: "center", md: "space-between" }} alignItems={{ base: "center", md: 'flex-start' }}>
                <Box width={{base: "100%", md: "auto"}} paddingBottom={{base: "48px", md: "0px"}}>
                    <Heading size="xl">Contact Us</Heading>
                    <Text size="md" color="gray.600" pb="4" pt="8px">Send us a message using the form on the right, or call our customer service</Text>
                    <Text color="gray.600" pb="4">CUSTOMER SERVICE HOURS OF OPERATION: </Text>

                    <VStack alignItems="flex-start" color="gray.600" pb="4" margin="0px">
                        <Text>Monday 9AM to 6PM EST </Text>
                        <Text mt="0px !important">Tuesday 9AM to 6PM EST </Text>
                        <Text mt="0px !important">Wednesday 9AM to 6PM EST</Text>
                        <Text mt="0px !important">Thursday 9AM to 6PM EST </Text>
                        <Text mt="0px !important"> Friday 9AM to 6PM EST </Text>
                    </VStack>
                    
                    <VStack alignItems="flex-start">
                        <HStack pb="4"><IconPhone fill="#E0B700" strokeWidth="0px" color="#E0B700" /> <Link href="tel:18004651525 " color="gray.900" marginInlineStart="26px !important" fontSize="20px">1 800 465 1525 </Link></HStack>
                        <HStack pb="4" mt="0px !important"><IconPhone fill="#E0B700" strokeWidth="0px" color="#E0B700" /> <Link href="tel:1-855-809-1510" marginInlineStart="26px !important" fontSize="20px">FAX 1-855-809-1511  </Link></HStack>
                        <HStack pb="4" mt="0px !important"><MdMail width="24px !important" height="24px !important"  color="#E0B700" /> <Link href="mailto:info@acemedicalwholesale.com" marginInlineStart="26px !important" fontSize="20px">info@acemedicalwholesale.com </Link></HStack>
                    </VStack>
                </Box>
                
                <Stack w={{md:"660px", base: "100%"}} spacing="6" background="white" padding="40px" marginInlineStart="0px !important" borderRadius="lg" boxShadow="0px 10px 15px -3px rgba(0, 0, 0, 0.1), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)">
                    <form onSubmit={handleSubmit}>
                        <Stack spacing="5">
                            <FormControl>
                                <FormLabel htmlFor="email">Full Name</FormLabel>
                                <Input id="fullName" type="text" name="fullName" required defaultValue={formValues.fullName} onChange={handleChange("fullName")} />
                                <ValidationError  prefix="fullName"  field="fullName" errors={state.errors}/>
                            </FormControl>

                            <FormControl marginTop="24px !important">
                                <FormLabel htmlFor="email">Email address</FormLabel>
                                <Input id="email" type="email" name="email" required defaultValue={formValues.email} onChange={handleChange("email")} />
                               <Text color="red" > <ValidationError prefix="Email"  field="email" errors={state.errors}/></Text>
                            </FormControl>

                            <FormControl marginTop="24px !important">
                                <FormLabel htmlFor="email">Company (optional)</FormLabel>
                                <Input id="email" type="text" name="Company" defaultValue={formValues.company} onChange={handleChange("company")} />
                            </FormControl> 

                            <FormControl marginTop="24px !important">
                                <FormLabel htmlFor="email">Message</FormLabel>
                                <Textarea id="Message" name="Message" required defaultValue={formValues.message} onChange={handleChange("message")} />
                                <ValidationError  prefix="Message"  field="Message" errors={state.errors}/>
                            </FormControl>
                        </Stack>
                        <Stack paddingTop="6">
                            <Button disabled={state.submitting} type="submit" variant="solid" size="lg" colorScheme="blue" rightIcon={<IconMail stroke="#FFD61F" color="#FFD61F" strokeWidth="2px" width="18px" height="18px" />} >Send Message</Button>
                        </Stack>
                    </form>
                </Stack>    
            </HStack>
        </Box>
  )
}
